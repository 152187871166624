import VueRouter from 'vue-router';

// Used Components
import Home from '@/views/Home';
import MasterLayout from '@/views/template/MasterLayout.vue';

import { isAuthenticated } from '@/utils/auth';
import store from '@/store';
import { requests } from '../utils/requests';

const { VUE_APP_SSO_PUBLIC_JWK_SET } = process.env;

const routes = [
  {
    path: '/',
    component: MasterLayout,
    children: [
      { path: '/', name: 'Home', component: Home }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  const user = await isAuthenticated(store.state.token, VUE_APP_SSO_PUBLIC_JWK_SET);

  if (!user) {
    window.location.href = `${window.location.origin}/logout`;
    return next(false);
  }

  if (!user.roles.includes('admin')) {
    // Get centers ids
    const centers = await requests.getCollabCenters(user.uuid, null).catch(err => {
      console.error(err);
    });

    if (centers && centers.success && centers.data && centers.data.length) {
      store.commit('setAvailableDestinations', centers.data);
    } else {
      store.commit('setAvailableDestinations', []);
    }
  } else {
    store.commit('setAvailableDestinations', ['*']);
  }

  store.commit('setUserPlatforms', user.writeWorkspaces);

  return next();
});

export default router;
